/**
 * Wrap text content in an element
 * @param {string} element  type of element to wrap in
 * @param {string} className  Optional class name
 * @returns {(text: string) => HTMLElement}
 */
const wrapIn = (element, className = '') => text => {
    const wrapper = document.createElement(element);
    if (className) wrapper.classList.add(className);
    wrapper.textContent = text;
    return wrapper;
}

/**
 * Append an array fo children to an element
 * @param {[HTMLElement]} children Children to append
 * @return {(element: HTMLElement) => HTMLElement}
 */
const appendChildren = children => element => {
    // Cloning to keep things immutable.
    const clone = element.cloneNode();
    children.forEach(child => {
        clone.appendChild(child);
    });
    return clone;
}

export { wrapIn, appendChildren }