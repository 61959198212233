import anime from 'animejs'
import VanillaTilt from 'vanilla-tilt'
import { wrapIn, appendChildren } from './helpers'
import Maps from './maps'

const mapsCenter = (width) => {
    if (width >= 1280) {
        return { lat: -4.000, lng: 71.500 }
    } else if (width >= 1024) {
        return { lat: -12.000, lng: 81.500 }
    } else if (width >= 768) {
        return { lat: 5.000, lng: 100.500 }
    }
    return { lat: -14.000, lng: 122.500 }
}
const initMap = Maps.mapSetup(document.getElementById('Map'), {
    center: mapsCenter(window.innerWidth),
    zoom: 3,
    disableDefaultUI: true,
    styles: Maps.styles.simplified
})

/**
 * Run our scripts
 */
const init = () => {
    // Initilise map
    initMap();

    // Get elements
    const word = document.getElementById('Eden');

    // Split our word and wrap each letter in a span
    const letters = word.textContent.split('').map(wrapIn('span', 'letter'));

    // Put all the pieces together
    const newWord = appendChildren(letters)(word);
    newWord.classList.add('word');

    // Replace the element
    word.parentNode.replaceChild(newWord, word);

    // Start our animation
    anime({
        targets: '#Eden > .letter',
        translateY: [20, 0],
        opacity: 1,
        delay: anime.stagger(50, {
            start: 1000
        }),
        spring: 'spring(100, 80, 50, 100)'
    });

    // Start Tilt on outer div
    VanillaTilt.init(document.querySelector('.tilt'), {
        reverse: true,
        perspective: 3000,
        max: 3,
        "full-page-listening": true,
        speed: 2000
    });

    // Start tilt on inner word for a more obvious effect
    VanillaTilt.init(document.querySelector('.wordTilt'), {
        reverse: true,
        perspective: 3000,
        max: 5,
        "full-page-listening": true,
        speed: 2000
    });
}

export default init;