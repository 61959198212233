// Styles Imports
import 'normalize.css'
import '/assets/scss/main.scss'

// Script Imports
import init from './init'

// Waits for the DOM to completely load before running our scripts
// All images, styles and external scripts have now been processed
window.onload = () => {
    // Initilise now
    init();
}